<template>
  <main>
    <CCard>
      <CCardHeader>
        <CRow class="d-flex align-items-center">
          <CCol col="6">
            <h5 class="mb-0">{{ $t('Navigation.ExperienceTicket/List') }}</h5>
          </CCol>
          <CCol col="6" class="text-md-right">
            <CDropdown size="sm" color="info" placement="bottom-start" class="d-inline-block">
              <template #toggler-content>
                <CIcon size="sm" name="cil-applications-settings" class="mr-1" />{{ $t('Organization.MemberAction') }}
              </template>
              <CDropdownItem @click="ExoprtCSV()">
                {{ $t('ExperienceTicket.Export') }}
              </CDropdownItem>
              <CDropdownItem @click="Delete()">
                {{ $t('Global.Delete') }}
              </CDropdownItem>
              <CDropdownItem @click="Update()">
                {{ $t('ExperienceTicket.Status.Y') }}
              </CDropdownItem>
            </CDropdown>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardHeader class="w-100 overflow-auto px-3">
        <CRow>
          <CCol>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('DataTimeRange')">
              {{ $t('Order.Filter.DataTime') }}: {{
                SearchFilter.StartTime ? $dayjs(SearchFilter.StartTime).format('YYYY-MM-DD HH:mm:ss') : '--'
              }} ~ {{ SearchFilter.EndTime ? $dayjs(SearchFilter.EndTime).format('YYYY-MM-DD HH:mm:ss') : '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Name')">
              分派幼校: {{ SearchFilter['Variable.SchoolDist'] || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="danger" shape="pill" class="mr-2" @click="ResetFilter()">
              <CIcon size="sm" name="cil-x"/>
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          class="overflow-scroll"
          :items="List"
          :fields="Field"
          :loading="Loading"
          :noItemsView="noItemsView"
          addTableClasses="small text-nowrap"
          responsive
          hover
        >
          <template #Checkbox-header>
            <label class="mb-0">
              <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
            </label>
          </template>
          <template #Checkbox="{item}">
            <td class="text-center">
              <label class="mb-0">
                <input type="checkbox" :id="'Checkbox-Post-' + item.Slug" :value="item.Slug" v-model="ChooseSlugs">
              </label>
            </td>
          </template>
          <template #CreateTime="{item}">
            <td class="text-center">
              {{ $dayjs(item.CreateTime).format('YYYY-MM-DD HH:mm:ss') }}
            </td>
          </template>
          <template #ParentName="{item}">
            <td class="text-center">
              {{ item.Variable.ParentName || '--' }}
            </td>
          </template>
          <template #ChildName="{item}">
            <td class="text-center">
              {{ item.Variable.ChildName || '--' }}
            </td>
          </template>
          <!-- <template #Identity="{item}">
            <td class="text-center">
              {{ item.Variable.Identity }}
            </td>
          </template> -->
          <template #PhoneNum="{item}">
            <td class="text-center">
              {{ item.Variable.PhoneNum }}
            </td>
          </template>
          <template #City="{item}">
            <td class="text-center">
              {{ item.Variable.City }}
            </td>
          </template>
          <template #Age="{item}">
            <td class="text-center">
              {{ item.Variable.BirthY }}/{{ item.Variable.BirthM }}
            </td>
          </template>
          <template #AvalibleTime="{item}">
            <td class="text-center">
              {{ item.Variable.AvalibleTime }}
            </td>
          </template>
          <template #SchoolDist="{item}">
            <td class="text-center">
              {{ item.Variable.SchoolDist }}
            </td>
          </template>
          <template #Email="{item}">
            <td class="text-st">
              {{ item.Variable.Email }}
            </td>
          </template>
          <template #Note="{item}">
            <td class="text-center">
              {{ item.Variable.Note }}
            </td>
          </template>
          <template #Ans1="{item}">
            <td class="text-center">
              <template v-if="item.Variable.Tickets[0]">
                <p>{{ item.Variable.Tickets[0].Name }}</p><br>
                <CImg fluid :src="item.Variable.Tickets[0].Img"/>
              </template>
            </td>
          </template>
          <template #Ans2="{item}">
            <td class="text-center">
              <template v-if="item.Variable.Tickets[1]">
                <p>{{ item.Variable.Tickets[1].Name }}</p><br>
                <CImg fluid :src="item.Variable.Tickets[1].Img"/>
              </template>
            </td>
          </template>
          <template #Status="{item}">
            <td class="text-center">
              <CBadge :color="(item.Status ? 'success' : 'danger')">
                {{ $t(`ExperienceTicket.Status.${item.Status ? 'Y' : 'N'}`) }}
              </CBadge>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <CCardFooter>
        <CPagination
          :activePage.sync="Pagination.Page"
          :pages.sync="Pagination.TotalPages"
          @update:activePage="ChangePage()"
        />
      </CCardFooter>
    </CCard>
    <CModal :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit" @update:show="ChangePage()">
      <CRow form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.StartTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.StartTime" mode="dateTime" is24hr :max-date="$dayjs(SearchFilter.EndTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.EndTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.EndTime" mode="dateTime" is24hr :min-date="$dayjs(SearchFilter.StartTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CInput label="分派幼校" v-model="SearchFilter['Variable.SchoolDist']" horizontal/>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="ChangePage()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
  "meta": {
    "label": "體驗券列表"
  }
}
</route>

<script>
export default {
  layout: 'manage',
  data() {
    return {
      List: [],
      Loading: false,
      noItemsView: {
        noResults: this.$t('Post/List.NoResults'),
        noItems: this.$t('Post/List.NoItems')
      },
      ChooseSlugs: [],
      CheckAllValue: false,
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      SearchFilter: {
        StartTime: this.$dayjs().subtract(1, 'M').unix() * 1000,
        EndTime: this.$dayjs().unix() * 1000,
        'Variable.SchoolDist': '',
      },
      FilterModel: false,
      Submit: false
    }
  },
  computed: {
    Field() {
      return [
        { key: 'Checkbox', label: '' },
        { key: 'CreateTime', label: this.$t('ExperienceTicket.CreateTime') },
        { key: 'ParentName', label: this.$t('ExperienceTicket.ParentName') },
        { key: 'ChildName', label: this.$t('ExperienceTicket.ChildName') },
        { key: 'PhoneNum', label: this.$t('ExperienceTicket.PhoneNum') },
        { key: 'City', label: this.$t('ExperienceTicket.City') },
        { key: 'Age', label: this.$t('ExperienceTicket.Age') },
        { key: 'AvalibleTime', label: this.$t('ExperienceTicket.AvailableTime') },
        { key: 'SchoolDist', label: this.$t('ExperienceTicket.SchoolDist') },
        { key: 'Email', label: this.$t('ExperienceTicket.Email') },
        { key: 'Note', label: this.$t('ExperienceTicket.Note') },
        { key: 'Ans1', label: this.$t('ExperienceTicket.Reserve') + '1' },
        { key: 'Ans2', label: this.$t('ExperienceTicket.Reserve') + '2' },
        { key: 'Status', label: this.$t('ExperienceTicket.Status.Label') },
      ]
    }
  },
  mounted() {
    return Promise.all([
      this.GetList(),
    ]).then(() => {
      return true
    }).catch((err) => {
      throw err
    })
  },
  methods: {
    GetList() {
      let RequestQuery = {
        page: this.$route.query.page || this.Pagination.Page,
        perPage: this.$route.query.perPage || this.Pagination.PerPage,
        order: this.$route.query.order || this.Order
      }
      Object.keys(this.SearchFilter).forEach((param) => {
        RequestQuery[param] = this.$route.query[param]
      })
      this.Loading = true
      this.FilterModel = false
      return this.$store.dispatch('InnerRequest', {
        url: '/experience_ticket/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.Loading = false
        this.List = data.list
        this.Pagination = data.pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    CheckAll() {
      if (this.CheckAllValue) {
        this.ChooseSlugs = this.List.map(data => {
          return data.Slug
        })
      } else {
        this.CheckAllValue = false
        this.ChooseSlugs = []
      }
    },
    OpenFilter() {
      this.FilterModel = true
    },
    ChangePage() {
      let query = {
        page: this.Pagination.Page,
        perPage: this.Pagination.PerPage,
        order: this.Order
      }
      if (this.SearchFilter.StartTime) query.StartTime = this.$dayjs(this.SearchFilter.StartTime).unix()
      if (this.SearchFilter.EndTime) query.EndTime = this.$dayjs(this.SearchFilter.EndTime).unix()
      if (this.SearchFilter['Variable.SchoolDist']) {
        query['Variable.SchoolDist'] = this.SearchFilter['Variable.SchoolDist']
      }
      return this.$router.replace({
        path: '/experience_ticket/list',
        query
      }).then(() => {
        this.GetList()
      })
    },
    ExoprtCSV() {
      this.Submit = true
      this.$Progress.start()

      const postData = {
        Slug: this.ChooseSlugs,
        PerPage: this.Pagination.TotalItems,
        'Variable.SchoolDist': this.SearchFilter['Variable.SchoolDist']
      }
      if(this.SearchFilter.StartTime) postData.StartTime = this.$dayjs(this.SearchFilter.StartTime).unix()
      if(this.SearchFilter.EndTime) postData.EndTime = this.$dayjs(this.SearchFilter.EndTime).unix()

      return this.$store.dispatch('InnerRequest', {
        url: 'experience_ticket/exportCSV',
        method: 'post',
        data: postData
      }).then((response) => {
        this.ResetState()
        this.Submit = true
        this.$Progress.start()
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/octet-stream'})
        const blobURL = URL.createObjectURL(Data)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', `預約名單_${this.$dayjs().format('YYYYMMDD')}.csv`, '_blank')
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
      })
    },
    Delete() {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/experience_ticket/delete',
        method: 'post',
        data: {
          Slug: this.ChooseSlugs
        }
      }).then(() => {
        this.GetList()
        this.ResetState()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/DeleteSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.ResetState()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/DeleteFail') + err.msg,
          type: 'error'
        })
      })
    },
    Update() {
      this.Submit = true
      if(this.ChooseSlugs.length === 0) return
      return this.$store.dispatch('InnerRequest', {
        url: '/experience_ticket/update',
        method: 'post',
        data: {
          Slug: this.ChooseSlugs,
          updateData: {
            Status: 1
          }
        }
      }).then(() => {
        this.GetList()
        this.ResetState()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/UpdateSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.ResetState()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/UpdateFail') + err.msg,
          type: 'error'
        })
      })
    },
    ResetState() {
      this.Submit = false
      this.AlertModel = false
      this.ChooseSlugs = []
    },
    ResetFilter() {
      this.SearchFilter = {
        StartTime: this.$dayjs().subtract(1, 'M').unix() * 1000,
        EndTime: this.$dayjs().unix() * 1000,
        'Variable.SchoolDist': '',
      }
      this.ChangePage()
    }
  }
}
</script>
